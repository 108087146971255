<script>
import { defineComponent, ref } from 'vue'
import HeinekenStarSVG from '@/assets/heineken-star.svg'

export default defineComponent({
  name: 'ThePreloader',
  components: { HeinekenStarSVG },
  setup () {
    return {
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <HeinekenStarSVG class="spinner" />
        <div class="copy">Stai per atterrare nel Metaverso di Heineken Silver...</div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "./assets/fonts/heineken-serif/index";

#app-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinner {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  animation: rotate 2s linear infinite;

  // .path {
  //   animation: dash 1.5s ease-in-out infinite;
  //   stroke: #fff;
  //   stroke-linecap: round;
  // }
}

.copy {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  padding-top: 150px;
  font-family: "Heineken Serif", serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 130%;
  text-align: center;
  transform: translate3d(-50%, -50%, 0);

  @screen md {
    font-size: 24px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

// @keyframes dash {
//   0% {
//     stroke-dasharray: 1, 150;
//     stroke-dashoffset: 0;
//   }

//   50% {
//     stroke-dasharray: 90, 150;
//     stroke-dashoffset: -35;
//   }

//   100% {
//     stroke-dasharray: 90, 150;
//     stroke-dashoffset: -124;
//   }
// }
</style>
